<template>
  <div>
    <header class="front-header-main">
      <!-- <marquee behavior="scroll" id="marquee" gap="0" truespeed="" :direction="direction" scrollamount="2" scrolldelay="50" onmouseover="this.stop();" onmouseout="this.start();" v-if="marque_text && marque_text != 'null'">
        <p>{{marque_text}}</p>
      </marquee> -->
      <div class="header-container-fluid container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div
              class="landing-header | d-flex align-items-center justify-content-between"
            >
              <router-link to="/" class="front-logo">
                <img class="nav-logo" :src="$auth.getFlatIcon()" alt="nav-logo" />
              </router-link>
              <div class="cta-btns d-flex align-items-start">
                <div class="switch-lang cust-switch" v-if="languageArray.length > 1">
                  <div class="current-lang">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="mdi-google-translate"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20,5H10.88L10,2H4A2,2 0 0,0 2,4V17A2,2 0 0,0 4,19H11L12,22H20A2,2 0 0,0 22,20V7A2,2 0 0,0 20,5M7.17,14.59A4.09,4.09 0 0,1 3.08,10.5A4.09,4.09 0 0,1 7.17,6.41C8.21,6.41 9.16,6.78 9.91,7.5L10,7.54L8.75,8.72L8.69,8.67C8.4,8.4 7.91,8.08 7.17,8.08C5.86,8.08 4.79,9.17 4.79,10.5C4.79,11.83 5.86,12.92 7.17,12.92C8.54,12.92 9.13,12.05 9.29,11.46H7.08V9.91H11.03L11.04,10C11.08,10.19 11.09,10.38 11.09,10.59C11.09,12.94 9.5,14.59 7.17,14.59M13.2,12.88C13.53,13.5 13.94,14.06 14.39,14.58L13.85,15.11L13.2,12.88M13.97,12.12H13L12.67,11.08H16.66C16.66,11.08 16.32,12.39 15.1,13.82C14.58,13.2 14.21,12.59 13.97,12.12M21,20A1,1 0 0,1 20,21H13L15,19L14.19,16.23L15.11,15.31L17.79,18L18.5,17.27L15.81,14.59C16.71,13.56 17.41,12.34 17.73,11.08H19V10.04H15.36V9H14.32V10.04H12.36L11.18,6H20A1,1 0 0,1 21,7V20Z"
                      />
                    </svg>
                    <p class="lang-text">{{ $store.state.lang.locale }}</p>
                  </div>
                  <div class="lang-dropdown">
                    <div
                      class="selecting-lang"
                      @click="swichLanguage(lang.value)"
                      v-for="(lang, i) in languageArray"
                      :key="i"
                    >
                      <p class="lang-text">{{ lang.text }}</p>
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                        v-if="lang.value == $store.state.lang.locale"
                      ></i>
                    </div>
                  </div>
                </div>
                <router-link
                  :to="{ name: 'sign-in' }"
                  class="btn btnnw-primary signinlnk-nw"
                  >{{ $t("Sign In") }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section
      class="search-start"
      :style="{
        background: 'url(' + backgroundImg + ') no-repeat',
        backgroundSize: 'cover !important',
        backgroundPosition: 'center !important',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-10 m-auto text-center">
            <div class="search-main">
              <h1 class="mb-4">{{ heading }}</h1>
              <div class="searchbar d-flex">
                <div class="search-box d-flex me-3 w-100">
                  <div class="position-relative w-100">
                    <vue-google-autocomplete
                      v-if="$auth.google_map_key"
                      id="map"
                      ref="address"
                      class="form-control pac-target-input"
                      type="text"
                      v-model="addresses"
                      v-on:placechanged="getAddressData"
                      :placeholder="placeholderValue"
                      types="establishment"
                      :country="$auth.setting.country_code_type"
                    ></vue-google-autocomplete>
                    <i class="fas fa-search search-icon"></i>
                    <a href="javascript:void(0)" class="current-location front-gps">
                      <div class="current-address">
                        <b-button
                          variant="primary"
                          class="nxt-btn"
                          disabled
                          v-if="is_btn_spinner"
                        >
                          <b-spinner small type="grow"></b-spinner>
                          {{ $t("Loading") }}...
                        </b-button>
                        <span v-if="!is_btn_spinner" @click="locatorButtonPressed">
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 512 512"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M255.863 168.699C207.816 168.699 168.723 207.793 168.723 255.844C168.723 303.891 207.816 342.984 255.863 342.984C303.914 342.984 343.008 303.891 343.008 255.844C343.008 207.793 303.914 168.699 255.863 168.699Z"
                              fill="#FF8300"
                            />
                            <path
                              d="M497.003 240.843H441.948C434.679 149.84 361.866 77.0233 270.859 69.7577V14.9961C270.859 6.71483 264.144 0 255.863 0C247.582 0 240.867 6.71483 240.867 14.9961V69.7577C149.859 77.0233 77.0467 149.84 69.7772 240.843H14.9961C6.71483 240.843 0 247.558 0 255.843C0 264.124 6.71483 270.839 14.9961 270.839H69.7772C77.0467 361.843 149.859 434.659 240.867 441.929V496.687C240.867 504.968 247.582 511.683 255.863 511.683C264.148 511.683 270.859 504.968 270.859 496.687V441.929C361.866 434.659 434.683 361.843 441.948 270.839H497.003C505.284 270.839 511.999 264.124 511.999 255.843C511.999 247.558 505.284 240.843 497.003 240.843V240.843ZM255.863 412.538C169.461 412.538 99.1678 342.242 99.1678 255.843C99.1678 169.441 169.461 99.1443 255.863 99.1443C342.265 99.1443 412.558 169.441 412.558 255.843C412.558 342.242 342.265 412.538 255.863 412.538Z"
                              fill="#FF8300"
                            />
                          </svg>
                          {{ $t("Use current location") }}</span
                        >
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="foodcat-main" v-if="sectionImages.length > 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <h1>{{ $t("In 3 easy steps") }}</h1>
          </div>
          <div
            class="col-md-4"
            v-for="sectionContent in sectionImages"
            :key="sectionContent.id"
          >
            <a :href="sectionContent.link" target="_blank">
              <div class="foodcat-card">
                <div class="foodcat-img">
                  <img
                    v-if="sectionContent.image"
                    :src="$auth.setting.section_image_path + '/' + sectionContent.image"
                    :alt="sectionContent.heading"
                  />
                </div>
                <div class="foodcat-con">
                  <h4>{{ sectionContent.heading }}</h4>
                  <p>{{ sectionContent.description }}</p>
                  <a
                    v-if="sectionContent.link"
                    :href="sectionContent.link"
                    target="_blank"
                    class="read-more-btn"
                    >{{ $t("Read more") }}</a
                  >
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="foodcat-main download-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center mb-5 str-icon">
            <h1>{{ $t("Download our app") }}</h1>
            <p>{{ $t("It couldn't be easier.") }}</p>
          </div>
          <a
            class="col-md-3 text-center pb-3"
            :href="$auth.setting && $auth.setting.ios_customer_app_url"
            ><img src="@/assets/images/app-store.png" alt="app-store"
          /></a>
          <a
            class="col-md-3 text-center"
            :href="$auth.setting && $auth.setting.android_customer_app_url"
            ><img src="@/assets/images/google-play.png" alt="android-store"
          /></a>
        </div>
      </div>
    </section>
    <section class="foodcat-main foot-sect">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="footernw-logo">
              <img :src="$auth.getFlatIcon()" alt="foot-logo" />
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <h4 class="mb-3">Contact</h4>
            <ul class="fusion-checklist fusion-checklist-1">
              <li class="fusion-li-item">
                <span class="icon-wrapper circle-yes"
                  ><i class="fusion-li-icon fa-mobile-alt fas" aria-hidden="true"></i
                ></span>
                <div class="fusion-li-item-content">Telefoon: 050 – 820 00 59</div>
              </li>
              <li class="fusion-li-item">
                <span class="icon-wrapper circle-yes"
                  ><i class="fusion-li-icon fa-envelope fas" aria-hidden="true"></i
                ></span>
                <div class="fusion-li-item-content">
                  <p>Email: info@gerechtaanhuis.nl</p>
                </div>
              </li>
              <li class="fusion-li-item">
                <span class="icon-wrapper circle-yes"
                  ><i class="fusion-li-icon fa-home fas" aria-hidden="true"></i
                ></span>
                <div class="fusion-li-item-content">
                  <p>Postadres:</p>
                  <p>Baken 30<br />9501 KN Stadskanaal</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-3 mb-3">
            <h4 class="mb-3">{{ $t("Justice at home") }}</h4>
            <ul
              class="fusion-checklist fusion-checklist-1 fusion-checklist-default type-icons"
            >
              <!-- <li class="fusion-li-item" v-for="page in pages">
                <a href="javascript:void(0)" @click="redirectUrl(page)">{{ page.name }}</a>
              </li> -->
              <li class="fusion-li-item">
                <a href="https://gerechtaanhuis.nl/admin/page/NDU5LDQwODE4"
                  >Klantenservice</a
                >
              </li>
              <li class="fusion-li-item">
                <a href="https://gerechtaanhuis.nl/admin/vendor/NDA4MTg=/signup"
                  >Restaurant aanmelden</a
                >
              </li>
              <li class="fusion-li-item">
                <a href="https://www.gerechtaanhuis.nl/admin/page/NDYxLDQwODE4">Word bezorger</a>
              </li>
              <li class="fusion-li-item">
                <a href="https://gerechtaanhuis.nl/admin/page/NDYwLDQwODE4">Vacatures</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3 mb-3">
            <h4 class="mb-3">{{ $t("Information") }}</h4>
            <ul
              class="fusion-checklist fusion-checklist-1 fusion-checklist-default type-icons"
            >
              <li class="fusion-li-item">
                <a href="https://www.gerechtaanhuis.nl/admin/page/NDU3LDQwODE4"
                  >Algemene voorwaarden</a
                >
              </li>
              <li class="fusion-li-item">
                <a
                  href="https://www.gerechtaanhuis.nl/admin/page/NDU1LDQwODE4"
                  target="_blank"
                  >Privacy statement</a
                >
              </li>
              <li class="fusion-li-item">
                <a href="https://www.gerechtaanhuis.nl/admin/page/NDU4LDQwODE4"
                  >Cookieverklaring</a
                >
              </li>
              <!-- <li class="fusion-li-item">
                <a href="https://www.gerechtaanhuis.nl/admin/page/NDU3LDQwODE4">Algemene voorwaarden</a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer-nw">
      <div class="container">
        <div class="row">
          <div class="col-md-10">
            <!-- <div class="d-flex flex-column justify-content-between h-100">
              <div class="footernw-logo">
                <img :src="$auth.getAppLogo()" alt="foot-logo">
                <p><span class="d-flex justify-content-center gap-1 align-items-center">Powered by <ul><a target="_blank" class="primary-link text-decoration-underline " href="https://deonde.co/">DEONDE</a></ul></span></p>                
              </div>
              <div class="footernw-store">
                <a v-if="$auth.setting && $auth.setting.ios_customer_app_url" :href="$auth.setting.ios_customer_app_url"><img src="@/assets/images/app-store.png" alt="app-store" /></a>
                <a v-if="$auth.setting && $auth.setting.android_customer_app_url" :href="$auth.setting.android_customer_app_url"><img src="@/assets/images/google-play.png" alt="android-store" /></a>
              </div>
            </div> -->
            <p>
              © Copyright {{ new Date().getFullYear() }} | Gerecht aan Huis |&nbsp;Alle
              Rechten onder voorbehoud&nbsp;|&nbsp;Design &amp; technische realisatie:
              <a href="https://www.m2idee.nl" rel="noreferrer" target="_blank">M2 !dee</a>
            </p>
          </div>
          <div class="col-md-2">
            <div class="footernw-left d-flex flex-flow-row gap-3 justify-content-end">
              <a :href="'//' + facebookLink && facebookLink != '' ? '#' : facebookLink"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a :href="'//' + twitterLink"><i class="fab fa-twitter"></i></a>
              <a :href="'//' + instagramLink"><i class="fab fa-instagram"></i></a>
              <!-- <a v-if="facebookLink" :href="'//' + facebookLink">{{ $t('Facebook') }}</a>
              <a v-if="twitterLink" :href="'//' + twitterLink">{{ $t('Twitter') }}</a>
              <a v-if="instagramLink" :href="'//' + instagramLink">{{ $t('Instagram') }}</a> -->
            </div>
          </div>
          <!-- <div class="col-md-12 footer-bottom" v-if="$auth && $auth.setting && $auth.setting.show_contact_developer == 'Yes'">
            <div class="footer-bottom-inner">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="footerother-links">
                    <p><span class="d-flex justify-content-center gap-1 align-items-center">Powered by <ul><a target="_blank" class="primary-link text-decoration-underline " href="https://deonde.co/">DEONDE</a></ul></span></p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <cookie-law theme="dark-lime">
        <div slot="message">
          {{ $t("This") }} {{ $auth.getAppName() }}
          {{
            $t(
              "uses cookies to provide you with a great user experience. Please accept cookies for excellent optimal performance"
            )
          }}.
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Login from "../apis/login";
import { getLanguageText } from "../helper/helper";
import CookieLaw from "vue-cookie-law";

export default {
  name: "front",
  components: { VueGoogleAutocomplete, CookieLaw },
  data() {
    return {
      addresses: "", //Input type in display for field
      marker: { position: { lat: 23, lng: 72 } },
      center: { lat: 23, lng: 72 },
      address: "",
      latitude: "",
      longitude: "",
      state: "",
      city: "",
      is_btn_spinner: false,
      placeholderValue: this.$t("Search for an area or Location name"),
      backgroundImg: "",
      heading: "",
      sectionImages: [],
      facebookLink: "",
      instagramLink: "",
      twitterLink: "",
      languageArray: [],
      marque_text: "",
      direction: "",
      pages: [],
    };
  },
  mounted() {
    // if (localStorage.getItem("address") || localStorage.getItem("latitude")) {
    //   this.$router.push({ name: "home" });
    // }
    setTimeout(() => {
      if (this.$auth.setting) {
        this.heading =
          this.$auth.setting.web_settings &&
          this.$auth.setting.web_settings.home_page_title;
        this.backgroundImg =
          this.$auth.setting &&
          this.$auth.setting.web_home_page_image_path &&
          this.$auth.setting.web_settings.home_page_image
            ? this.$auth.setting.web_home_page_image_path +
              "/" +
              this.$auth.setting.web_settings.home_page_image
            : "";
        if (
          this.$auth.setting.web_settings &&
          this.$auth.setting.web_settings.front_images
        ) {
          this.sectionImages = this.$auth.setting.web_settings.front_images;
        }
        this.facebookLink =
          this.$auth.setting.web_settings &&
          this.$auth.setting.web_settings.facebook_link;
        this.instagramLink =
          this.$auth.setting.web_settings && this.$auth.setting.web_settings.instagram;
        this.twitterLink =
          this.$auth.setting.web_settings && this.$auth.setting.web_settings.twitter;

        this.languageArray = [
          {
            value: this.$auth.setting.primary_lang,
            text: getLanguageText(this.$auth.setting.primary_lang),
          },
        ];
        if (this.$auth.setting.secondary_lang) {
          this.languageArray.push({
            value: this.$auth.setting.secondary_lang,
            text: getLanguageText(this.$auth.setting.secondary_lang),
          });
        }
        this.marque_text =
          this.$auth.setting.terminology && this.$auth.setting.terminology[0].marque_text;
        if (this.$store.state.lang.locale == "ar") {
          this.direction = "right";
        } else {
          this.direction = "left";
        }
        this.setStaticPages();
      }
    }, 2000);

    let host = window.location.hostname;
    if (host == "localhost") {
      host = "gerechtaanhuis.nl";
    }

    if (host.includes("www.")) {
      host = host.replace("www.", "");
    }
    navigator.geolocation.getCurrentPosition((position) => {
      this.marker.position = {
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      };
      localStorage.setItem("userCurrentlatitude", parseFloat(position.coords.latitude));
      localStorage.setItem("userCurrentlongitude", parseFloat(position.coords.longitude));
    });

    let localLang = localStorage.getItem("localelang");
    if (localLang && localLang == null && localLang == "") {
      Login.getLanguageSettings(host).then((response) => {
        if (response.data.code == 200) {
          if (
            response.data.Result.primary_language &&
            !this.$store.getters["lang/updatedLang"]
          ) {
            this.$store.dispatch(
              "lang/changeLangLocale",
              response.data.Result.primary_language
            );
          }
        }
      });
    }
  },

  methods: {
    getLanguageText,
    getAddressData: function (addressData, placeResultData) {
      this.is_btn_spinner = true;
      this.addresses = placeResultData.formatted_address;
      this.address = placeResultData.formatted_address;
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.marker.position = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      var value = this.address.split(", ");
      var value1 = this.address.split(" ");
      var count = value.length;
      var count1 = value1.length;
      this.locality = value[count - 4];
      this.city = value[count - 3];
      this.state = value[count - 4];
      localStorage.setItem("address", this.addresses);
      localStorage.setItem("latitude", this.latitude);
      localStorage.setItem("longitude", this.longitude);
      localStorage.setItem("city", this.city);
      localStorage.setItem("state", this.state);
      localStorage.setItem("userCurrentlatitude", this.latitude);
      localStorage.setItem("userCurrentlongitude", this.longitude);
      this.addresses = "";
      this.$router.push({ name: "home" });
    },

    locatorButtonPressed() {
      this.is_btn_spinner = true;
      this.placeholderValue = this.$t("Fetching Your location..");
      let vm = this;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.marker.position = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };
          this.latitude = parseFloat(position.coords.latitude);
          this.longitude = parseFloat(position.coords.longitude);
          if (!this.$auth.google_map_key) {
            return false;
          }
          localStorage.setItem(
            "userCurrentlatitude",
            parseFloat(position.coords.latitude)
          );
          localStorage.setItem(
            "userCurrentlongitude",
            parseFloat(position.coords.longitude)
          );
          this.locateAddress(position.coords.latitude, position.coords.longitude);
        },
        function (error) {
          vm.is_btn_spinner = false;
          vm.placeholderValue = this.$t("Search for an area or Location name");
        }
      );
    },

    locateAddress(lat, lng) {
      if (!this.$auth.google_map_key) {
        return false;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.address = results[0].formatted_address;
          var value = this.address.split(", ");
          var value1 = this.address.split(" ");
          var count = value.length;
          var count1 = value1.length;
          this.locality = value[count - 4];
          this.state = value1[count1 - 3];
          this.city = value[count - 3];
          this.zip_code = value1[count1 - 2];
          localStorage.setItem("address", this.address);
          localStorage.setItem("latitude", this.latitude);
          localStorage.setItem("longitude", this.longitude);
          localStorage.setItem("geolocation", "yes");
        }
      });
      this.address = localStorage.getItem("address");
      // this.$router.push({ name: "home" });
    },

    swichLanguage(lang) {
      localStorage.setItem("localelang", lang);
      this.$store.dispatch("lang/changeLangLocale", lang);
      window.location.reload();
    },

    setStaticPages() {
      if (this.$auth.setting) {
        if (
          this.$auth.setting.static_pages &&
          this.$auth.setting.static_pages.length > 0
        ) {
          let pgs = [];
          this.$auth.setting.static_pages.forEach((ele) => {
            if (ele.display_in.customer == "1") {
              pgs.push({ name: ele.page_name, url: ele.url });
            }
          });
          this.is_load_skeleton = false;
          this.loadIframe = false;
          this.pages = pgs;
        } else {
          this.is_load_skeleton = false;
        }
      }
    },

    redirectUrl(page) {
      localStorage.setItem("isStaticPage", JSON.stringify(page));
      this.$router.push({ name: "pages" });
    },
  },
};
</script>
<style scoped>
.primary-link:hover,
.primary-link {
  color: rgba(var(--theme-primary)) !important;
}

.search-start {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

#marquee {
  position: relative;
  width: 100%;
  background: #eee;
  overflow: hidden;
  margin: -30px 0 0;
  display: inline-block;
  vertical-align: top;
}

#marquee p {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: var(--theme-font-primary);
  font-size: 13px !important;
  margin: 0;
  padding: 5px 0;
}

.download-sec h1 {
  font-weight: 500;
  margin: 0;
  font-size: 50px;
}

.download-sec p {
  font-weight: 400;
  margin: 0;
  font-size: 24px;
}

.foot-sect .footernw-logo img {
  height: 215px;
}
li.fusion-li-item {
  display: flex;
  align-items: start;
  gap: 10px;
}
span.icon-wrapper.circle-yes {
  color: var(--theme-button-text-second-color);
}
i.fusion-li-icon.fa-home.fas {
  display: inline-block;
}
ul li:not(:last-child) {
  margin-bottom: 10px;
}
.fusion-checklist .icon-wrapper,
ul.fusion-checklist .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  float: left;
  width: var(--awb-icon-width);
  height: var(--awb-icon-height);
  margin-inline-end: #f0f0ed;
}
.search-start {
  height: 100vh !important;
}
@media only screen and (max-width: 767px) {
  .header-container-fluid.container-fluid {
    --bs-gutter-x: 3rem;
  }
  .download-sec .str-icon img {
    width: 50%;
  }
  .foodcat-main {
    padding: 0;
  }
  .search-start {
    height: 100vh !important;
  }
}
</style>
